import React from "react";
import {Col, Image, Row} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import getThumbnailPath from "./getThumbnailPath";
import _ from "lodash";
import Measure from "react-measure";
import ArtFooter from "./ArtFooter";

class Work extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      work: {year: ""},
      dimensions: {
        width: -1,
        height: -1,
      }
    }

    fetch("/api/work/" + (this.props.match.params.workId) + ".json")
      .then(res => res.json())
      .then(
        (work) => {
          this.setState({
            work: work
          });
        }
      )
  }


  throttledSetState = _.debounce(this.setState, 500, {
    'leading': true
  })

  render() {
    return <Measure
      bounds
      onResize={contentRect => {
        if(contentRect.bounds.height > this.state.dimensions.height || contentRect.bounds.width > this.state.dimensions.width){
          this.throttledSetState({ dimensions: contentRect.bounds })
        }
      }}
    >
      {({measureRef}) => (<>
        <div className={"mb-5 row work mt-2"} style={{textAlign: "center"}}>
          {this.state.work.image ? <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              width: "fit-content",
              padding: "0rem"
            }}
          >
            <Image
                className={"workImage"}
                ref={measureRef}
                style={this.state.dimensions.width > this.state.dimensions.height * 1.4 ? {
                  display: "block",
                  minWidth: "40px",
                  width: "80vw",
                  maxWidth: "800px",
                } : {
                  display: "block",
                  visibility: this.state.dimensions.width === -1 || this.state.dimensions.width === 40  ? "hidden" : "visible",
                  minWidth: "40px",
                  height: "80vw",
                  maxHeight: "55vh",
                }}
                alt={this.state.work.title}
                src={getThumbnailPath(this.state.work, Math.floor(this.state.dimensions.width), Math.floor(this.state.dimensions.height))}
                height={"100%"}

              />
            <div style={{
              textAlign: "left",
              height: 0
            }}>
              <ArtFooter art={this.state.work} />
            </div>
          </div> : ""}
        </div>
        <Row style={{marginTop: "6rem", marginBottom: "3rem"}}>
          <Col className={"wDescription"}>
            {this.state.work.description}
          </Col>
        </Row>
      </>)}
    </Measure>
  }


}

export default withRouter(Work);
