import React from "react";


const ArtFooter = ({art, shouldRenderDate=true, shouldRenderMaterial=true}) => {
  return <>
    <h6 className={"pt-1 pb-0"} style={{marginBottom:0}}>{art.title}</h6>
    {shouldRenderDate ? <i style={{fontSize: "0.8rem"}} className={"py-0"}>
      {shouldRenderMaterial ? `${art.work_height} x ${art.work_width}; ${art.material}; ${art.year}`: art.year}
    </i> : ""}
  </>
}

export default ArtFooter