import './App.scss';

import {Col, Container, Row} from 'react-bootstrap';
import React from "react";

function CV() {
  return <Container style={{textAlign: "center"}}>
    <h2>CV</h2>
    <Row className={"my-5"}>
      <Col className={"cv-buttons"}>
        <a className={"btn btn-secondary"} href={"/2021 CV LvO solo Nl volledig.pdf"}
           target="_blank"
           rel={"noreferrer"}
        >
          Solo NL
        </a>
        <a className={"btn btn-secondary"} href={"/CV Konjets Vjeka.pdf"}
           target="_blank"
           rel={"noreferrer"}
        >
          Konjets Vjeka
        </a>
        <a className={"btn btn-secondary"} href={"/CV Lilian YM .pdf"}
           target="_blank"
           rel={"noreferrer"}
        >
          YM
        </a>
      </Col>
    </Row>
  </Container>
}

export default CV;
