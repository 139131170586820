import './App.scss';

import {Col, Row} from 'react-bootstrap';
import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      introduction: "",
    }
    fetch("/api/introduction/?format=json")
      .then(res => res.json())
      .then(result => result.pop())
      .then((intro => this.setState({introduction: intro.description})))
  }


  render = () => {
    return <div>
      <div style={{textAlign: "center"}}>
        <h2>Introductie</h2>
      </div>
      <Row className={"my-5 intro-content"}>
        <Col dangerouslySetInnerHTML={{ __html: this.state.introduction}}>
        </Col>
      </Row>
    </div>
  }
}

export default About;
