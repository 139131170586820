import './App.scss';
import {Container, Nav, Navbar, Row} from "react-bootstrap";
import {BrowserRouter as Router, NavLink, Redirect, Route, Switch} from "react-router-dom";
import Contact from "./Contact";
import About from "./About";
import CV from "./CV";
import Collection from "./gallery/Collection";
import Work from "./gallery/Work";
import React from "react";
import WithGalleryNav from "./gallery/WithGalleryNav";


function App() {
  return <Router>
    <Navbar fixed={"top"} sticky={"top"}>
      <Container>
        <Navbar.Brand href="/">
          <img
            src="/favicon.svg"
            width="60"
            height="60"
            className="d-inline-block align-top"
            alt="Lilian"
          />
        </Navbar.Brand>
        <Nav className={"ms-auto"}>
          <NavLink to="/kunst" activeClassName="active" className={"nav-link"}>Kunst</NavLink>
          <NavLink to="/introductie" activeClassName="active" className={"nav-link"}>Introductie</NavLink>
          <NavLink to="/cv" activeClassName="active" className={"nav-link"}>CV</NavLink>
          <NavLink to="/contact" activeClassName="active" className={"nav-link"}>Contact</NavLink>
        </Nav>
      </Container>
    </Navbar>
    <Container>
      <Row className={"my-4 my-md-5"}>
        <Switch>
          <Route path="/kunst/collectie/*">
            <WithGalleryNav>
              <Collection/>
            </WithGalleryNav>
          </Route>
          <Route path="/kunst/werk/:workId/collectie/*">
            <WithGalleryNav>
              <Work/>
            </WithGalleryNav>
          </Route>
          <Route path="/kunst">
            <WithGalleryNav>
              <Collection collectionTitle={"top"}/>
            </WithGalleryNav>
          </Route>
          <Route path="/introductie">
            <About/>
          </Route>
          <Route path="/cv">
            <CV/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          <Route path="/">
            <Redirect to="/kunst"/>
          </Route>
        </Switch>
      </Row>
    </Container>
  </Router>;
}

export default App;
