const route = (params) => {
  const keys = Object.keys(params)
    .filter(param => !isNaN(
      param.replace(/\//g, ''))
    )
    .sort((a, b) => a - b)

  return keys.map(key => params[key]).join("/");
}

export default route;