import React from "react";
import {Container, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import getWildcardRoute from "./getWildcardRoute";
import getThumbnailPath from "./getThumbnailPath";
import Measure from 'react-measure';
import _ from 'lodash';

class CollectionPreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: [...(this.props.collection.works || []), ...(this.props.collection.collections || [])],
      dimensions: {
        width: 0,
        height: 0,
      },
      smallDimensions: {
        width: 0,
        height: 0,
      },
    }
  }


  render() {
    if (this.state.items.length === 0) {
      return this.renderEmptyCollectionPreview();
    }
    return this.renderItemPreviews();
  }

  renderEmptyCollectionPreview() {
    return <></>
  }

  throttledSetDimensions = _.debounce(this.setState, 500, {
    'leading': true
  })

  throttledSetSmallDimensions = _.debounce(this.setState, 500, {
    'leading': true
  })

  renderItemPreviews() {
    let {smallWorks, mainWork} = this.getMainAndSmallWorks();

    return <Container
      key={this.props.collection.id}
      className={"container p-0 h-100 overflow-hidden gallery-image-container collection-preview"}
      onClick={() => this.navigateToCollection()}>
      {this.renderMainWork(mainWork)}
      {this.renderSmallWorks(smallWorks)}
    </Container>
  }

  getMainAndSmallWorks() {
    const items = [...this.state.items]
    const firstItemIsWork = !!items[0].image

    if (firstItemIsWork) {
      const mainWork = items.shift().image
      return {smallWorks: items, mainWork};
    }

    const firstItemHasAWork = items[0].works && items[0].works.length > 0;
    if (firstItemHasAWork) {
      return this.getPreviewsFromFirstCollection(items);
    }

    if (items[0].collections && items[0].collections.length > 0) {
      if (items[0].collections[0].works && items[0].collections[0].works.length > 0) {
        return this.getPreviewsFromNestedCollection(items);
      }
    }

    // bad, shouldn't happen
    return {smallWorks: items, mainWork: {}};
  }

  getPreviewsFromNestedCollection(items) {
    const mainWork = items[0].collections[0].works[0].image;
    const smallWorks = _.cloneDeep(items)
    smallWorks[0].collections[0].works.shift()
    return {
      smallWorks,
      mainWork
    };
  }

  getPreviewsFromFirstCollection(items) {
    const mainWork = items[0].works[0].image
    const smallWorks = _.cloneDeep(items)
    smallWorks[0].works.shift()
    return {
      smallWorks,
      mainWork
    };
  }

  renderMainWork(image) {
    const {width, height} = this.state.dimensions

    return <Measure
      bounds
      onResize={contentRect => {
        if (contentRect.bounds.height > this.state.dimensions.height || contentRect.bounds.width > this.state.dimensions.width) {
          this.throttledSetDimensions({dimensions: contentRect.bounds})
        }
      }}
    >
      {({measureRef}) => (<Row className={"h-70"} ref={measureRef}>
        {this.state.dimensions.height ?
          <div
            className={"gallery-image main-preview"}
            style={{backgroundImage: "url('" + getThumbnailPath({image}, Math.floor(width), Math.floor(height)) + "')"}}/>
          : ""}
      </Row>)}
    </Measure>;
  }

  renderSmallWorks(items) {
    const {width, height} = this.state.smallDimensions

    return <Measure
      bounds
      onResize={contentRect => {
        if (contentRect.bounds.height > this.state.smallDimensions.height || contentRect.bounds.width > this.state.smallDimensions.width) {
          this.throttledSetSmallDimensions({smallDimensions: contentRect.bounds})
        }
      }}
    >
      {({measureRef}) => (
        <Row className={"h-30 mx-0"} ref={measureRef}>
          {items.filter((work, i) => i < 3).map((item) => {
            return (item.image ? this.state.smallDimensions.height ? <div key={this.props.collection.id + "-" + item.id}
                                                                          className={"col col-4 p-0 secondary-preview gallery-image"}
                                                                          style={{backgroundImage: "url('" + getThumbnailPath(item, Math.floor(width), Math.floor(height)) + "')"}}/> : "" :
              <div key={this.props.collection.id + "-" + item.id} className={"col col-4 p-0 secondary-preview"}>
                <CollectionPreviewWithRouter collection={item}/>
              </div>)
          })}
        </Row>)}
    </Measure>;
  }

  navigateToCollection = () => {
    let newPath = "/kunst/collectie/" + this.props.collection.id;
    if (getWildcardRoute(this.props.match.params).length > 0) {
      newPath = "/kunst/collectie/" + getWildcardRoute(this.props.match.params) + "/" + this.props.collection.id;
    }
    this.props.history.push(newPath);
  }
}

const CollectionPreviewWithRouter = withRouter(CollectionPreview);
export default CollectionPreviewWithRouter;
