import GalleryNavigation from "./GalleryNavigation";
import React from "react";
import {Container} from "react-bootstrap";


const withNav = (props) => {
  return <Container className={"gallery"}>
    <GalleryNavigation/>
    {props.children}
  </Container>
}

export default withNav;