import React from "react";
import {withRouter} from 'react-router-dom';
import Gallery from "./Gallery";
import _ from "lodash"


class Collection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collection: {},
      items: []
    }
    this.updateAndNotify();
  }

  updateAndNotify = () => {
    console.log(this.props);
    this.constructCollectionQuery()().then(
      (collection) => {
        this.setState({
          items: [...collection.works, ...collection.collections],
          collection
        });
      }
    )
  }

  constructCollectionQuery = () => {
    return this.props.collectionTitle ? this.fetchCollectionByTitleFromProps : this.fetchCollectionByIdFromUrl;
  }

  // Used for retrieving the top collection`
  fetchCollectionByTitleFromProps = () => {
    return fetch("/api/collection/?title=" + this.props.collectionTitle + "&format=json")
      .then(res => res.json())
      .then(result => result.pop())
  }

  fetchCollectionByIdFromUrl = () => {
    const collections = Object.values(this.props.match.params);
    const collectionId = collections[collections.length - 1].split("/").pop();
    return fetch("/api/collection/" + collectionId + ".json").then(res => res.json());
  }

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState({
        collection: {},
        items: []
      });
      this.updateAndNotify();
    }
  }

  render = () => <Gallery
    items={this.state.items}
    collection={this.state.collection}
  />

}

export default withRouter(Collection);
