import React from "react";
import {Container} from "react-bootstrap";
import {Link, withRouter} from 'react-router-dom';
import getWildcardRoute from "./getWildcardRoute";

class GalleryNavigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {items: [], collections: null}
    fetch("/api/collection/?title=top&format=json")
      .then(res => res.json())
      .then(result => {
        this.setState({items: result.pop().collections.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))});
      })
    fetch("/api/collection/?format=json")
      .then(res => res.json())
      .then(collections => {
        this.setState({
          collections: collections.reduce((result, col) => {
            result[col.id] = col;
            return result;
          }, {})
        });
      })
  }

  render() {
    let collectionIds = getWildcardRoute(this.props.match.params).split("/");
    const isWorkPage = this.props.match.params.workId !== undefined;

    return <Container style={{textAlign: "center"}} className={"gallery-navigation"}>
      {this.state.items.map(item => {
        const isActive = this.props.match.params[0] && this.props.match.params[0].split("/")[0] == item.id;
        return <div key={item.id}
             className={`btn btn-inverse btn-sm ${isActive ? "btn-dark" : "btn-secondary"} m-1`}
             onClick={this.navigateToCollection(item)}
        >
          {item.title}
          {isActive ? <div className={"close-icon"}>x</div> : ""}
        </div>
      })}

      {this.state.collections && (collectionIds.length > 1 || isWorkPage) ? <div style={{fontSize: "1rem"}}>
        <span>Terug naar: {this.renderBreadCrumbs(collectionIds, isWorkPage)}</span>
      </div> : ""}

    </Container>
  }

  navigateToCollection = (item) => {
    return () => {
      let newPath = "/kunst/collectie/" + item.id;
      if (this.props.match.params[0] && this.props.match.params[0].split("/")[0] == item.id) {
        newPath = "/kunst";
      }
      this.props.history.push(newPath);
    };
  }

  renderBreadCrumbs = (collectionIds, isWorkPage) => {
    return collectionIds.map((id, i) => {
      let newPath = "/kunst/collectie/" + collectionIds.slice(0, i + 1).join("/");
      if (i + 1 < collectionIds.length || isWorkPage) {
        return <>
          <Link to={newPath}>
            {this.state.collections[id].title}
          </Link>
          {i + 1 === collectionIds.length && isWorkPage || i + 2 === collectionIds.length && !isWorkPage ? "" :
            <span> > </span>}
        </>
      }
    })
  }
}

export default withRouter(GalleryNavigation);
