import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import CollectionPreview from "./CollectionPreview";
import {withRouter} from "react-router-dom";
import WorkPreview from "./WorkPreview";
import { v4 as uuidv4 } from 'uuid';
import getWildcardRoute from "./getWildcardRoute";
import ArtFooter from "./ArtFooter";


class Gallery extends React.Component {

  render() {
    return <div className={"gallery-content"}>
      {this.renderOptionalDescription()}
      {this.renderPreviews()}
    </div>
  }

  renderOptionalDescription = () => {
    const collectionIds = getWildcardRoute(this.props.match.params).split("/");
    const isHighLevelCollection = collectionIds.length < 2;
    return <>
      { this.props.collection.title === "top" || (isHighLevelCollection && !this.props.collection.description) ? "" :
      <Row className={"gallery-description"}>
        <Container className={"p-md-5 p-4 col-xl-10"}>
          { isHighLevelCollection ? "" : <h2>{this.props.collection.title}</h2>}
          {this.props.collection.description ? this.props.collection.description  : ""}
        </Container>
      </Row>}
    </>;
  }

  renderPreviews = () => {
    return <Row>
      {this.props.items.map((item) => {
        const isCollection = !item.image;
        return <Col key={uuidv4()} className={"gallery-item col-12 col-md-6 col-lg-4 p-4"}>
          {!isCollection ?
            <WorkPreview item={item} /> :
            <CollectionPreview collection={item}/>}
          <Row>
            <ArtFooter art={item} shouldRenderMaterial={!isCollection} shouldRenderDate={this.props.collection.title !== "top"} />
          </Row>
        </Col>
      })}
    </Row>
  }

  renderPreviewTitle = (item) => {
    return <h6 className={"py-2 pb-0"} style={{marginBottom:0}}>
      {item.title}
    </h6>
  }

}

export default withRouter(Gallery);
