import {Link, withRouter} from "react-router-dom";
import React from "react";
import getWildcardRoute from "./getWildcardRoute";
import getThumbnailPath from "./getThumbnailPath";
import Measure from "react-measure";
import {Container, Row} from "react-bootstrap";
import _ from "lodash";


class WorkPreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: 40,
        height: 40
      }
    }
  }

  getWorkPath = (item) => {
    return "/kunst/werk/" + this.props.item.id + "/collectie/" + getWildcardRoute(this.props.match.params);
  }


  throttledSetState = _.debounce(this.setState, 500, {
    'leading': true
  })

  render() {
    const {width, height} = this.state.dimensions

    return <Measure
      bounds
      onResize={contentRect => {
        if(contentRect.bounds.height > this.state.dimensions.height || contentRect.bounds.width > this.state.dimensions.width){
          this.throttledSetState({ dimensions: contentRect.bounds })
        }
      }}
    >
      {({measureRef}) => (
        <Container
          className={"overflow-hidden w-100 h-100 p-0  gallery-image-container"}
        >
          <Link to={this.getWorkPath(this.props.item)}>
            <Row className={"h-100 m-0"}>
              <div
                ref={measureRef}
                className={"h-100 gallery-image"}
                style={{
                  backgroundImage: 'url("' + getThumbnailPath(this.props.item, Math.floor(width), Math.floor(height)) + '")'
                }}
              />
            </Row>
          </Link>
        </Container>
      )}

    </Measure>
  }
}

export default withRouter(WorkPreview);
