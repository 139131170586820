import './App.scss';

import {Col, Container, Row} from 'react-bootstrap';
import React from "react";

function Contact() {
  return <Container style={{textAlign: "center"}}>
    <Row>
      <Col className={"col-12 col-sm-6 mb-4 mt-3"}>
        <h2>Contactgegevens</h2>
      <br></br>
        <div style={{listStyle: "none", margin:"0",
          paddingLeft: "12px", lineHeight: "1.5"}}>
          <div className={"contactRow"}>
           Email :
           <span> livanopdorp@ziggo.nl</span>
          </div>
          <div className={"contactRow"}>
            Facebook :
            <a  href={"https://facebook.com/lilian.vanopdorp"}>facebook.com/lilian.vanopdorp</a>
          </div>
          <div className={"contactRow"}>
            Instagram :
            <a  href={"https://instagram.com/lilianvanopdorp"}>instagram.com/lilianvanopdorp</a>
          </div>
          <div className={"contactRow"}>
            Linkedin :
            <a  href={"https://linkedin.com/in/lilianvanopdorp"}>linkedin.com/in/lilianvanopdorp</a>
          </div>
        </div>
        <br></br>
      <br></br>
        <h5>Studio Adress </h5>
        <div style={{listStyle: "none", margin:"0",
          paddingLeft: "12px"}}>
            <div>St. Walburgstraat 24/10</div>
            <div>9712HX</div>
            <div>Groningen</div>
        </div>
      </Col>
      <Col className={"col-12 col-sm-6 mt-3"}>
        <img
          src="/Lilian-van-opdorp.jpeg"
          width="100%"
          height="auto"
          className="d-inline-block align-top"
          alt="Lilian"
        />
      </Col>
    </Row>
  </Container>

}

export default Contact;
